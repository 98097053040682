@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Rubik", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.nunito {
  font-family: "Nunito", sans-serif;
}

.bannerbg {
  background-image: url("./Assets/Images/Banner3.jpg");
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

.animate {
  animation: move 4s linear infinite;
}

@keyframes move {
  0% {
    margin-left: 8px;
  }
  100% {
    margin-left: 110px;
  }
}
